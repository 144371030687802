<template>
  <vx-card>
    <b-button
        to="/new/service"
        color="primary"
        type="gradient"
        class="bg-h-primary px-12 py-3 mb-3 text-white"
      >
        <b-icon icon="plus"></b-icon>
         درخواست خدمت جدید
      </b-button>
      <div v-if="serviceList.length > 0">
        <b-table
          class="text-center align-middle"
          head-variant="dark"
          responsive
          :stacked="windowWidth < 800"
          thead-class="bg-red"
          :fields="fields"
          :items="serviceList"
        >
          <template #cell(show)="row">
              <b-button
                variant="info"
                size="sm"
                @click="$router.push(`/service/details/${row.item.id}`)"
                class="text-center bg-h-primary  "
              >
              مشاهده درخواست
              </b-button>
            </template>

          <template #cell(status_string)="data">
            <span class=" ">{{data.value}}</span>
          </template>
        </b-table>
      </div>
       <div class="flex flex-column justify-center items-center w-full" v-else>
        <img
          width="150"
          src="@/assets/images/elements/open-box.svg"
          alt="haio-logo"
        />
        <h5>موردی جهت نمایش وجود ندارد</h5>
      </div>
  </vx-card>
</template>

<script>
export default {
  data () {
    return {
      serviceList: [],
      fields: [
        { key: 'id', label: 'شماره  خدمات' },
        { key: 'status_string', label: 'وضعیت' },
        { key: 'category', label: 'دسته بندی' },
        { key: 'show', label: 'عملیات', tdClass: 'align-middle' }

      ]
    }
  },
  methods: {
    getServicesList () {
      this.$http
        .get('/service/list')
        .then((result) => {
          this.serviceList = result.data.params.data
        })
        .catch()
    }
  },
  computed: {
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  created () {
    this.getServicesList()
  }
}
</script>

<style scoped>

.swal2-icon {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
</style>
